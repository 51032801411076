<template>
    <erros-box :erros="erros"></erros-box>
    <div class="formgrid grid p-fluid">
        <div class="field col-12 md:col-2" v-if="!v$.$invalid && arquivoAlterado">
            <Button label="Salvar" icon="pi pi-check-circle" class="p-button-primary" @click="confirmarSalvar()" />
        </div>
        <div class="field col-12 md:col-2" v-if="evento?.imagemCredenciamento && !arquivoAlterado">
            <Button label="Excluir Definitivamente" icon="pi pi-times-circle" class="p-button-danger" @click="confirmarExcluir()" />
        </div>
        <div class="field col-12 md:col-10">
            <FileUpload
                :customUpload="true"
                @uploader="arquivoSelecionado"
                mode="basic"
                accept="image/*"
                chooseLabel="Selecionar Arquivo"
                :auto="true"
                v-if="!arquivo || !nomeArquivo"
            />
            <div v-if="arquivo && nomeArquivo">
                <strong>{{ nomeArquivo }}</strong>
                <i class="pi pi-trash p-error icon-button ml-2" style="fontsize: 1.2rem" @click="limparArquivo()"></i>
                <btn-download-imagem-credenciamento
                    :evento="evento"
                    v-if="!arquivoAlterado && evento?.nomeImagemCredenciamento"
                ></btn-download-imagem-credenciamento>
            </div>
        </div>
        <div class="field col-12 justify-content-center" v-if="evento?.imagemCredenciamento && !arquivoAlterado">
            <img :src="`data:image/PNG;base64,${evento.imagemCredenciamento}`" alt="imagemCredenciamento" class="imagem-credenciamento" />
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import BtnDownloadImagemCredenciamento from './BtnDownloadImagemCredenciamento';
import EventosServices from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    components: {
        BtnDownloadImagemCredenciamento,
    },

    props: {
        acao: {
            type: String,
        },

        evento: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar', 'atualizar'],

    data() {
        return {
            arquivo: null,
            nomeArquivo: null,
            arquivoAlterado: false,
        };
    },

    validations() {
        return {
            arquivo: {
                conteudoOK: () => {
                    return this.conteudoOK;
                },
            },
        };
    },

    methods: {
        preencher() {
            if (this.evento) {
                this.nomeArquivo = this.evento?.nomeImagemCredenciamento;
                this.arquivo = {};
            }
            this.arquivoAlterado = false;
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja salvar a imagem do credencimento?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let imagemCredenciamentoDto = {
                eventoId: this.evento.eventoId,
                imagemCredenciamento: this.arquivo,
            };
            this.$store.dispatch('addRequest');
            EventosServices.atualizarImagemCredenciamento(imagemCredenciamentoDto)
                .then((response) => {
                    if (response?.success) {
                        this.$emit('atualizar');
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        arquivoSelecionado(event) {
            this.arquivo = event.files[0];
            this.nomeArquivo = this.arquivo.name;
            this.arquivoAlterado = true;
        },

        limparArquivo() {
            this.arquivo = null;
            this.arquivoAlterado = true;
        },

        confirmarExcluir() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir a imagem do credencimento?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            EventosServices.excluirImagemCredenciamento(this.evento.eventoId)
                .then((response) => {
                    if (response?.success) {
                        this.$emit('atualizar');
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },

    computed: {
        conteudoOK() {
            return this.arquivo !== null;
        },
    },

    watch: {
        evento() {
            this.preencher();
        },
    },

    created() {
        this.preencher();
    },
};
</script>

<style scoped>
.imagem-credenciamento {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>
