<template>
    <ProgressBar mode="indeterminate" style="height: 1em" v-if="carregando" />
    <TabView v-model:activeIndex="activeIndex" v-if="!carregando">
        <TabPanel header="Processados">
            <tabela :data="checkinsProcessados" :globalFilterFields="['codigoExterno', 'participante', 'protocolo']"
                dataKey="checkinId" stateKey="dt-checkins-processados">
                <template #botoes>
                    <Button label="Gerar Atendimentos" icon="pi pi-plus" @click="confirmarGerarAtendimentos()"
                        v-if="$temAcessoView('CHECKINS-EVENTOS-06') && evento?.geraAtendimento"
                        :disabled="checkinsSelecionados.length == 0" />
                    <Button label="Participantes (PDF)" icon="pi pi-file-pdf"
                        @click="emitirRelatorioParticipantes('pdf', false)"
                        v-if="$temAcessoView('CHECKINS-EVENTOS-09') && checkinsProcessados?.length > 0" class="ml-2" />
                    <Button label="Participantes Anonimizados (PDF)" icon="pi pi-file-pdf"
                        @click="emitirRelatorioParticipantes('pdf', true)"
                        v-if="$temAcessoView('CHECKINS-EVENTOS-12') && checkinsProcessados?.length > 0" class="ml-2" />
                    <Button label="Participantes (EXCEL)" icon="pi pi-file-excel"
                        @click="emitirRelatorioParticipantes('xlsx', false)"
                        v-if="$temAcessoView('CHECKINS-EVENTOS-13') && checkinsProcessados?.length > 0" class="ml-2" />
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em" v-if="evento?.geraAtendimento">
                        <template #header>
                            <font-awesome-icon icon="check-square" @click="toggleCheckins()" size="lg"
                                class="icon-button" title="Marcar/Desmarcar Todos" />
                        </template>
                        <template #body="slotProps">
                            <div class="flex flex-nowrap">
                                <Checkbox class="ml-2" :value="slotProps.data" v-model="checkinsSelecionados"
                                    v-if="mostrarChkBox(slotProps.data.permiteIntegracao)" />
                            </div>
                        </template>
                    </Column>
                    <Column headerStyle="width: 7em">
                        <template #body="slotProps">
                            <btn-detalhar :checkin="slotProps.data"></btn-detalhar>
                        </template>
                    </Column>
                    <Column field="codigoExterno" header="Código" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.codigoExterno }}</span>
                        </template>
                    </Column>
                    <Column field="participante" header="Participante" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.participante }}</span>
                        </template>
                    </Column>
                    <Column field="dataLeitura" header="Data Leitura" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ $dateFormat(slotProps.data.dataLeitura, 'DD/MM/YYYY HH:mm:ss') }}</span>
                        </template>
                    </Column>
                    <Column field="statusIntegracaoInternaDescr" header="Atendimento Solicitado" :sortable="true"
                        v-if="evento.geraAtendimento">
                        <template #body="slotProps">
                            <span
                                v-if="slotProps.data.statusIntegracaoInternaDescr == 'AGUARDANDO SOLICITAÇÃO DE INTEGRAÇÃO'">NÃO</span>
                            <span v-else>SIM</span>
                        </template>
                    </Column>
                    <Column field="dataCadastro" header="Recebido em" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ $dateFormat(slotProps.data.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}</span>
                        </template>
                    </Column>
                    <Column field="cpfResponsavel" header="Responsável" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.cpfResponsavel }}</span>
                        </template>
                    </Column>
                </template>
            </tabela>
        </TabPanel>
        <TabPanel header="Não Processados">
            <tabela :data="checkinsNaoProcessados" :globalFilterFields="['codigoExterno', 'statusDescr', 'observacoes']"
                dataKey="checkinId" stateKey="dt-checkins-naoprocessados">
                <template #conteudo>
                    <Column field="codigoExterno" header="Código" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.codigoExterno }}</span>
                        </template>
                    </Column>
                    <Column field="dataLeitura" header="Data Leitura" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ $dateFormat(slotProps.data.dataLeitura, 'DD/MM/YYYY HH:mm:ss') }}</span>
                        </template>
                    </Column>
                    <Column field="statusDescr" header="Status" :sortable="true">
                        <template #body="slotProps">
                            <status :status="slotProps.data.statusDescr"></status>
                        </template>
                    </Column>
                    <Column field="dataCadastro" header="Recebido em" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ $dateFormat(slotProps.data.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}</span>
                        </template>
                    </Column>
                    <Column field="observacoes" header="Observações" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.observacoes }}</span>
                        </template>
                    </Column>
                    <Column field="cpfResponsavel" header="Responsável" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.cpfResponsavel }}</span>
                        </template>
                    </Column>
                </template>
            </tabela>
        </TabPanel>
    </TabView>
</template>

<script>
import EventosServices from './services';
import BtnDetalhar from './BtnDetalhar';

export default {
    components: {
        BtnDetalhar,
    },

    props: {
        evento: {
            type: Object,
        },
    },

    data() {
        return {
            carregando: false,
            checkinsProcessados: null,
            checkinsNaoProcessados: null,
            checkinsSelecionados: [],
            activeIndex: 0,
        };
    },

    methods: {
        toggleCheckins() {
            if (this.checkinsSelecionados && this.checkinsSelecionados.length > 0) {
                this.checkinsSelecionados = [];
            } else {
                this.checkinsSelecionados = [
                    ...this.checkinsProcessados.filter((item) => {
                        return item.permiteIntegracao;
                    }),
                ];
            }
        },

        confirmarGerarAtendimentos() {
            this.$confirm.require({
                message: `Tem certeza que deseja solicitar a geração de atendimentos para os checkins selecionados?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.gerarAtendimentos();
                },
            });
        },

        gerarAtendimentos() {
            this.$store.dispatch('addRequest');
            let participantesCheckinsLista = this.checkinsSelecionados.map((item) => {
                return {
                    eventoId: this.evento.eventoId,
                    participanteId: item.participanteId,
                    checkinId: item.checkinId,
                };
            });

            let eventosParticipantesCheckinsLista = {
                eventosParticipantesCheckinsLista: [...participantesCheckinsLista],
            };

            EventosServices.gerarAtandimento(this.evento.eventoId, eventosParticipantesCheckinsLista)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Checkins',
                            detail: 'Solicitação para geração dos atendimentos realizada com sucesso',
                            life: 3000,
                        });
                        this.checkinsSelecionados = [];
                        this.obterCheckins();
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Checkins',
                            detail: 'Erro ao solicitar a geração dos atendimentos',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        obterCheckins() {
            this.carregando = true;
            EventosServices.obterCheckinsEvento(this.evento.eventoId)
                .then((response) => {
                    if (response?.success) {
                        this.checkinsProcessados = [...response.data.processados];
                        this.checkinsNaoProcessados = [...response.data.naoProcessados];
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Checkins',
                            detail: 'Erro ao obter checkins do evento',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.carregando = false;
                });
        },

        mostrarChkBox(permiteIntegracao) {
            return this.evento.geraAtendimento && permiteIntegracao;
        },

        emitirRelatorioParticipantes(tipo, anonimizado) {
            this.$store.dispatch('addRequest');
            EventosServices.emitirRelatorioParticipantes(this.evento.eventoId, tipo, anonimizado)
                .then((response) => {
                    if (response?.success) {
                        this.$download(response.data, `RelacaoParticipantes_${this.evento.codigo}.${tipo}`);
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Anexo',
                            detail: 'Erro ao emitir relação dos participantes',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },

    watch: {
        evento() {
            if (this.evento) {
                this.obterCheckins();
            }
        },
    },
    mounted() {
        if (this.evento) {
            this.obterCheckins();
        }
    },
};
</script>
