import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECKINS_PORT}${process.env.VUE_APP_API_PATH}/checkins`;
const urlBase = `${api}/eventos`;

export default {

    obterCheckinsEvento(eventoId) {
        return axiosJwt.get(`${urlBase}/${eventoId}/checkins`);
    },

    gerarAtandimento(eventoId, eventoParticipantesCheckins) {
        return axiosJwt.post(`${urlBase}/${eventoId}/checkins/geraratendimentos`, eventoParticipantesCheckins);
    },

    obterPorId(eventoId, participanteId, checkinId) {
        return axiosJwt.get(`${urlBase}/${eventoId}/checkins/${participanteId}/${checkinId}`);
    },

    emitirRelatorioParticipantes(eventoId, tipo, anonimizado) {
        let url = `${urlBase}/${eventoId}/checkins/participantes`;
        url += `/${tipo}`;
        if (anonimizado) {
            url += '/anonimizados';
        }
        return axiosJwt.get(`${url}`, {
            responseType: 'blob',
        });
    },
};