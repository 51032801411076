<template>
    <ProgressBar mode="indeterminate" style="height: 1em" v-if="carregando" />
    <TabView v-model:activeIndex="activeIndex" v-if="!carregando">
        <TabPanel header="">
            <tabela :data="checkinsProcessados" :globalFilterFields="['codigoExterno', 'participante', 'protocolo']"
                dataKey="checkinId" stateKey="dt-checkins-processados">
                <template #conteudo>
                    <Column headerStyle="width: 3em" v-if="evento?.geraAtendimento">
                    </Column>
                    <Column headerStyle="width: 7em">
                        <template #body="slotProps">
                            <btn-detalhar :checkin="slotProps.data"></btn-detalhar>
                        </template>
                    </Column>
                    <Column field="codigoExterno" header="Código" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.codigoExterno }}</span>
                        </template>
                    </Column>
                    <Column field="participante" header="Participante" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.participante }}</span>
                        </template>
                    </Column>
                    <Column field="statusIntegracaoInternaDescr" header="Status Atendimento" :sortable="true"
                        v-if="evento.geraAtendimento">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.statusIntegracaoInternaDescr }}</span>
                        </template>
                    </Column>
                    <Column field="protocolo" header="Protocolo" :sortable="true" v-if="evento.geraAtendimento">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.protocolo }}</span>
                        </template>
                    </Column>
                </template>
            </tabela>
        </TabPanel>
    </TabView>
</template>

<script>
import EventosServices from './services';
import BtnDetalhar from '../checkins/BtnDetalhar.vue';

export default {
    components: {
        BtnDetalhar,
    },

    props: {
        evento: {
            type: Object,
        },
    },

    data() {
        return {
            carregando: false,
            checkinsProcessados: null,
            checkinsNaoProcessados: null,
            checkinsSelecionados: [],
            activeIndex: 0,
        };
    },

    methods: {

        obterCheckins() {
            this.carregando = true;
            EventosServices.obterCheckinsEvento(this.evento.eventoId)
                .then((response) => {
                    if (response?.success) {
                        this.checkinsProcessados = [...response.data.processados];
                        this.checkinsNaoProcessados = [...response.data.naoProcessados];
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Checkins',
                            detail: 'Erro ao obter checkins do evento',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.carregando = false;
                });
        },
    },

    watch: {
        evento() {
            if (this.evento) {
                this.obterCheckins();
            }
        },
    },
    mounted() {
        if (this.evento) {
            this.obterCheckins();
        }
    },
};
</script>
